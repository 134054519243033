import { Link } from 'gatsby'
import React from 'react'
import { Container } from 'starterUiComponents'
import { graphql, useStaticQuery } from 'gatsby'

const QUATERLY_QUERY = graphql`
  query {
    allQuaterly {
      nodes {
        name
      }
    }
  }
`
const prettyQuarter = (i) => ['First', 'Second', 'Third', 'Fourth'][i - 1]

const QuaterlyItem = ({ sectionInfo, zipFileName, exeFileName }) => {
  const bucket = process.env.GATSBY_FIREBASE_STORAGE_BUCKET
  const baseUrl =
    `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/quaterly%2F`
  const quarter = prettyQuarter(sectionInfo.slice(sectionInfo.indexOf('qtr') + 3))
  const year = "20" + sectionInfo.slice(0, sectionInfo.indexOf('qtr'))

  return (
    <div>
      <h3>
        {quarter} Quarter {year}:
      </h3>
      {zipFileName && <p>
        <a href={`${baseUrl}${zipFileName}?alt=media&8b598e41-c0f9-40a2-855c-a29b37ea3405`} >
          ZIP File
        </a>
      </p>}
      {exeFileName && <p>
        <a href={`${baseUrl}${exeFileName}?alt=media&8b598e41-c0f9-40a2-855c-a29b37ea3405`} >
          Self Extracting Archive
        </a>
      </p>}
      <hr />
    </div>
  )
}

const QuarterlyBulletinsPage = () => {
  const quaterlyFiles = useStaticQuery(QUATERLY_QUERY).allQuaterly.nodes.map(node => {
    node.name = node.name.replace("quaterly/", "")
    return node
  }).filter(node => node.name).sort((a, b) => {
    return b.name > a.name ? 1 : -1
  }).reduce((acc, file) => {
    const fileNameNoExt = file.name.slice(0, file.name.indexOf("."))
    const fileExt = file.name.slice(file.name.indexOf(".") + 1)
    if (acc[fileNameNoExt]) {
      acc[fileNameNoExt][fileExt] = file.name
    } else {
      acc[fileNameNoExt] = {
        [fileExt]: file.name
      }
    }
    return acc;
  }, {})

  const quaterlySections = Object.keys(quaterlyFiles).sort((a, b) => {
    return b > a ? 1 : -1
  })

  return (
    <Container>
      <h1>Quarterly Tech Library</h1>
      <p>
        You may download all of the bulletins for a quarter in one file. They
        are stored as compressed ("ZIP") files. These ZIP files will "expand"
        into the separate Acrobat ("PDF") files for each bulletin. You may
        download the ZIP files in in either of two formats. A regular ZIP file
        (with the .zip extension), or as a "self extracting archive" or
        "executable" file. If you get the regular ZIP file you will need a
        program to "unzip" it. If you get the self extracting archive you just
        run it after you download it and it will expand the files.
      </p>
      <div>
        <Link to="/tech-library">Go back to Tech Library</Link>
        <hr />
        {quaterlySections?.map(sectionInfo => (
          <QuaterlyItem
            key={sectionInfo}
            sectionInfo={sectionInfo}
            zipFileName={quaterlyFiles[sectionInfo]["zip"]}
            exeFileName={quaterlyFiles[sectionInfo]["exe"]}
          />
        ))}
      </div>
      <div>
        <p>There are no archives for bulletins earlier than 2002</p>
        <p>Return to Numeric Index</p>
      </div>
    </Container>
  )
}

export default QuarterlyBulletinsPage
